import { combineReducers } from "redux";
import auth from "./authReducer";
import cart from "./cartReducer";
import event from "./eventReducer";
import global from "./globalReducer";

export default combineReducers({
  global,
  auth,
  cart,
  event,
});
