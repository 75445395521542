import moment from "moment";
import { letterColors } from "../styled/colors";
import { geocodeByAddress } from "react-places-autocomplete";

export const colorPicker = async (value: any) => {
  let colorValue: any = null;

  letterColors &&
    (await letterColors?.find((obj: any) => {
      let key = Object?.keys(obj);
      if (key.length && key[0] == value) {
        let value = Object?.values(obj);
        colorValue = value[0];
        return value[0];
      }
    }));

  return colorValue;
};

export const isDobValid = (dob: any) => {
  const birthDate = moment(dob);
  const currentDate = moment();
  const age = currentDate.diff(birthDate, "years");
  return age >= 18;
};

export const isTimeSelectionValid = (st: string, et: string) => {
  if (!st || !et) return false;

  const options = [
    { id: 1, label: "10:00 AM", value: "10:00 AM" },
    { id: 2, label: "11:00 AM", value: "11:00 AM" },
    { id: 3, label: "12:00 PM", value: "12:00 PM" },
    { id: 4, label: "01:00 PM", value: "01:00 PM" },
    { id: 5, label: "02:00 PM", value: "02:00 PM" },
    { id: 6, label: "03:00 PM", value: "03:00 PM" },
    { id: 7, label: "04:00 PM", value: "04:00 PM" },
    { id: 8, label: "05:00 PM", value: "05:00 PM" },
  ];

  const startTime = options.find((option: any) => option.value === st);
  const endTime = options.find((option: any) => option.value === et);

  // @ts-ignore
  if (startTime?.id >= endTime?.id) return false;
  return true;
};

export const getAddressByLatLng = async (lat, lng) => {
  if (!lat || !lng) return "";

  const geocoder = new window.google.maps.Geocoder();
  const latlng = { lat: parseFloat(lat), lng: parseFloat(lng) };

  return new Promise((resolve, reject) => {
    geocoder.geocode({ location: latlng }, (results, status) => {
      if (status === "OK") {
        if (results?.[0]) {
          const addressComponents = results[0].address_components;
          let city = "";
          let state = "";

          addressComponents.forEach((component) => {
            if (component.types.includes("locality")) {
              city = component.long_name;
            }
            if (component.types.includes("administrative_area_level_1")) {
              state = component.short_name; // Abbreviated state (e.g., CA, TX)
            }
          });

          if (city && state) {
            resolve(`${city}, ${state}`);
          } else {
            resolve("City or state not found");
          }
        } else {
          resolve("No results found");
        }
      } else {
        reject("Geocoder failed due to: " + status);
      }
    });
  });
};

export const timeFromNow = (dbDate: any) => {
  const timezone = process.env.REACT_APP_TIME_ZONE!;
  // Create a moment object from the database date
  const date = moment.tz(dbDate, timezone);

  // Return the time from now (e.g., "3 hours ago")
  return date.fromNow();
};
