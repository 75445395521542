import React, { useState } from "react";
import styled from "styled-components";
import { Flexed, Text } from "../../styled/shared";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Lightbox from "react-image-lightbox";
const SellersProducts = (content) => {
  const images = content.content.reduce((acc, cv) => {
    return [...acc, ...cv.images];
  }, []);

  const [lightBoxOpen, setLightBoxOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  const AWS_URL = "https://imagescontent.s3.us-east-1.amazonaws.com";

  return (
    <div>
      <OwlCarousel
        responsive={Responsive}
        nav={true}
        autoplay
        className="owl-carousel owl-theme"
      >
        {/* // <OwlCarousel responsive={Responsive} autoplay autoplayTimeout={3000} loop className="owl-carousel owl-theme"> */}
        {images?.map((img, index) => {
          return (
            <Img
              key={"carousel" + index}
              src={`${AWS_URL}/${img}`}
              onClick={() => {
                setPhotoIndex(index);
                setLightBoxOpen(true);
              }}
            />
          );
        })}
      </OwlCarousel>
      {lightBoxOpen && (
        <Lightbox
          mainSrc={`${AWS_URL}/${images[photoIndex]}`}
          nextSrc={`${AWS_URL}/${images[photoIndex]}`}
          prevSrc={`${AWS_URL}/${images[photoIndex]}`}
          onCloseRequest={() => setLightBoxOpen(false)}
          onMovePrevRequest={() => {
            setPhotoIndex(
              photoIndex === images.length - 1 ? 0 : photoIndex + 1
            );
          }}
          onMoveNextRequest={() => {
            setPhotoIndex(
              photoIndex === 0 ? images.length - 1 : photoIndex - 1
            );
          }}
        />
      )}
    </div>
  );
};

const Img = styled.img<any>`
  object-fit: cover;
  width: 200px;
  height: 200px;
  border-radius: 1.25rem;
`;

const Responsive = {
  0: {
    items: 1,
    margin: 20,
  },
  600: {
    items: 2,
    margin: 20,
  },
  992: {
    items: 2,
    // loop: false,
    margin: 20,
  },
  1400: {
    items: 3,
    // loop: false,
    margin: 20,
  },
};

export default SellersProducts;
