import React, { FC, useState } from "react";
import styled from "styled-components";
import { Flexed, Spacer, Text } from "../styled/shared";
import { useLocation, useNavigate } from "react-router-dom";
import { palette } from "../styled/colors";
import { FaFacebookF } from "react-icons/fa";
import { ImTwitter } from "react-icons/im";
import { AiFillLinkedin, AiFillYoutube } from "react-icons/ai";
import moment from "moment";

interface ISidebarFooter {
  scrollPosition?: number;
  screenHeight?: number;
  setSelectCategory?: any;
  display?: string;
}

const SidebarFooter: FC<ISidebarFooter> = ({
  scrollPosition,
  screenHeight,
  setSelectCategory,
  display,
}) => {
  const { pathname }: any = useLocation();
  let navigate = useNavigate();

  return (
    <FooterWrapper
      scroll={scrollPosition}
      isHome={pathname === "/community" || pathname === "/products"}
      screenHeight={screenHeight}
      display={display}
    >
      <Spacer height={2} />
      <Flexed
        flexWrap="wrap"
        gap={0.2}
        direction="column"
        align="center"
        justify="center"
      >
        <div>
          <a href="/seller-aggrement" target="_blank" rel="noopener noreferrer">
            <StyledText pointer fontSize={0.95} type="normal" color="text">
              Seller Aggrement
            </StyledText>
          </a>
        </div>
        <Flexed direction="row" gap={0.2} align="center" justify="center">
          <a href="/about-us" target="_blank" rel="noopener noreferrer">
            <StyledText pointer fontSize={0.95} type="normal" color="text">
              About
            </StyledText>
          </a>

          <Text>|</Text>
          <div
            onClick={() => {
              // setSelectCategory("support");
              navigate("/settings");
            }}
          >
            <StyledText pointer fontSize={0.95} type="normal" color="text">
              Contact
            </StyledText>
          </div>
          <Text>|</Text>
          <a href="/legal-policies" target="_blank" rel="noopener noreferrer">
            <StyledText pointer fontSize={0.95} type="normal" color="text">
              Legal
            </StyledText>
          </a>
          <Text>|</Text>
        </Flexed>
        <a href="/privacy-policies" target="_blank" rel="noopener noreferrer">
          <StyledText pointer fontSize={0.95} type="normal" color="text">
            Privacy
          </StyledText>
        </a>
      </Flexed>
      <Spacer height={1} />
      <Content>
        <Image src="/images/gambaLogo.png" />
      </Content>
      <Spacer height={2} />

      <Flexed direction="row" gap={1}>
        <a
          href="https://www.facebook.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <ArrowAvatar className="footer-logo">
            <FaFacebookF />
          </ArrowAvatar>
        </a>
        <a
          href="https://www.twitter.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <ArrowAvatar className="footer-logo">
            <ImTwitter />
          </ArrowAvatar>
        </a>
        <a
          href="https://www.linkedin.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <ArrowAvatar className="footer-logo">
            <AiFillLinkedin />
          </ArrowAvatar>
        </a>
        <a
          href="https://www.youtube.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <ArrowAvatar className="footer-logo">
            <AiFillYoutube />
          </ArrowAvatar>
        </a>
      </Flexed>
      <Spacer height={1} />

      <Flexed>
        <Text color="text" type="small">
          © Gamba | {moment().format("YYYY")}
        </Text>
      </Flexed>
      <Spacer height={0.7} />
    </FooterWrapper>
  );
};

const FooterWrapper = styled.div<any>`
  display: ${({ scroll, isHome, screenHeight, display }) => {
    if (display) {
      return display;
    } else if (isHome && scroll > screenHeight / 2) {
      return "block";
    } else {
      return "none";
    }
  }};
`;

const StyledText = styled(Text)`
  &:hover {
    color: ${palette.green_200};
    text-decoration: underline;
    text-underline-offset: 3px;
  }
`;

const Content = styled.div`
  width: 6rem;
  /* height: 7rem; */
`;

const Image = styled.img`
  width: 100%;
  cursor: pointer;
  /* height: 3.809rem; */
`;

const ArrowAvatar = styled.div`
  vertical-align: middle;
  width: 2.188rem;
  height: 2.188rem;
  border-radius: 50%;
  border: 0.063rem solid ${palette.text_description};
  display: flex;
  justify-content: center;
  color: ${palette.text_description} !important;
  align-items: center;
  cursor: pointer;

  &:hover {
    color: ${palette.green_200};
    border: 0.063rem solid ${palette.green_200};
  }
`;

export default SidebarFooter;
