import React, { useEffect, useState } from "react";
import { Col } from "styled-bootstrap-grid";
import DropDown from "../DropDown";
import { Flexed, Spacer, Text } from "../../styled/shared";
import styled from "styled-components";
import { palette } from "../../styled/colors";
import DateTimePicker from "react-datetime-picker";
import Checkbox from "../common/CheckBox";
import CustomInputField from "../common/CustomInputField";
import Toggle from "../common/Toggle";
import ChemicalsErrorModal from "../modals/ChemicalsErrorModal";
import AllowToOrderModal from "../modals/AllowToOrderModal";
import { BiCalendarAlt } from "react-icons/bi";
import { media } from "styled-bootstrap-grid";
// import { toastSuccess } from "../../styled/toastStyle";
import { toast } from "react-toastify";
import AutoComplete from "../common/AutoComplete";
// import { FaExclamation } from "react-icons/fa";
import GooglePlacesInput from "../GooglePlacesInput";
import Button from "../common/Button";
import AddChemicalForm from "./AddChemicalForm";

const ProductStepperTwo = ({
  setIsFarmerMarket,
  isFarmerMarket,
  isTrade,
  chemicalCheckBoxNone,
  setChemicalCheckBoxNone,
  setIsOrganicError,
  isOrganicError,
  setAllowToOrderOptions,
  allowToOrderOptions,
  privateLocation,
  isPickUp,
  setprivateLocation,
  setInventoryPriceError,
  inventoryPrice,
  inventoryPriceError,
  setInventoryPrice,
  setIsPickUp,
  allowToOrderError,
  setAllowToOrderError,
  chemicalsError,
  setChemicalsError,
  distanceError,
  setDistanceError,
  setDistance,
  distance,
  setAllowToOrder,
  allowToOrder,
  hoursOptions,
  setHoursOptions,
  startDateError,
  setDays,
  daysError,
  handleStartDateChange,
  startDate,
  setDaysOption,
  daysOption,
  days,
  setDaysError,
  setHoursError,
  hoursError,
  setHours,
  hours,
  endDateError,
  handleEndDateChange,
  endDate,
  setCaptionError,
  setCaption,
  captionError,
  caption,
  setQuantityError,
  quantityError,
  quantity,
  setQuantity,
  setToggle,
  setIsAddChemicalsModalOpen,
  toggle,
  chemicals,
  chemicalsUsed,
  setChemicalsUsed,
  unLimitted,
  setUnlimited,
  isDelivery,
  setIsDelivery,
  setPickupLocation,
  pickStartTime,
  pickEndTime,
  setPickStartTime,
  setPickEndTime,
  pickTimeErr,
  setPickTimeErr,
  setChemicals,
}) => {
  const [chemicalForm, setChemicalForm] = useState(false);
  const [isChemicalsErrorModalOpen, setIsChemicalsErrorModalOpen] =
    useState(false);
  const [isAllowToOrderModalOpen, setIsAllowToOrderModalOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedDays, setSelectedDays] = useState<string[]>([]);

  const options = [
    { id: 1, label: "10:00 AM", value: "10:00 AM" },
    { id: 2, label: "11:00 AM", value: "11:00 AM" },
    { id: 3, label: "12:00 PM", value: "12:00 PM" },
    { id: 4, label: "01:00 PM", value: "01:00 PM" },
    { id: 5, label: "02:00 PM", value: "02:00 PM" },
    { id: 6, label: "03:00 PM", value: "03:00 PM" },
    { id: 7, label: "04:00 PM", value: "04:00 PM" },
    { id: 8, label: "05:00 PM", value: "05:00 PM" },
  ];

  // const minTime = new Date();
  // minTime.setHours(10, 0, 0, 0); // 10:00 AM

  // const maxTime = new Date();
  // maxTime.setHours(17, 0, 0, 0); // 5:00 PM

  useEffect(() => {
    if (showModal) {
      toast.success(
        "If you choose organic, it means that no chemicals were used on your crops. But if you use other natural products to treat your crops, please list it in the product description",
        {
          autoClose: 10000,
        }
      );
    }
  }, [showModal]);

  const chemicalCreated = (payload: string) => {
    setChemicals((prev: any) => [...prev, payload]);
    setChemicalForm(false);
  };

  const handleCheck = (day: string) => {
    console.log({ day });

    let updated: string[] = [];
    if (selectedDays.includes(day)) {
      updated = selectedDays.filter((d) => d !== day);
    } else {
      updated = [...selectedDays, day];
    }

    setSelectedDays(updated);
  };

  return (
    <>
      {isTrade === "Sell" ? (
        <>
          <Col lg={6}>
            <CustomInputField
              // disabled={!allowToOrder}
              label="Allow to order in advance "
              bgTransparent
              modal={true}
              setIsAllowToOrderModalOpen={setIsAllowToOrderModalOpen}
              // type="number"
              placeholder="Enter"
              handleChange={(value: any) => {
                let reg: any = "";
                if (value === "") {
                  setDaysError("Days is required");
                }
                setDaysError("");
                if (allowToOrder === "Hour(s)") {
                  reg = /^(?:[0-9]|1[0-9]|2[0-3])?$/;

                  if (reg.test(value.toString())) {
                    setDays(value.toString());
                  }
                } else if (allowToOrder === "Day(s)") {
                  reg = /^(?:[1-9]|10)?$/;
                  if (reg.test(value.toString())) {
                    setDays(value.toString());
                  }
                }
              }}
              required
              error={daysError}
              allowOnlyNumbers={true}
              errorMsg={daysError}
              value={days}
            />
            <Spacer height={1.5} />
          </Col>

          <Col lg={6}>
            <CustomSpacer />
            <DropDown
              // disabled={!allowToOrder}
              firstSelected={allowToOrder}
              required
              // name="unit"
              hasChanged={(value: any) => {
                setAllowToOrderError("");
                if (value === "") {
                  setAllowToOrderError("Allow To Order is required");
                }
                setAllowToOrder(value);
                if (value === "Day(s)") {
                  setDays(1);
                } else if (value === "Hour(s)") {
                  setDays(0);
                }
              }}
              error={allowToOrderError}
              errorMsg={allowToOrderError}
              options={allowToOrderOptions}
            />
            <Spacer height={1.5} />
          </Col>
        </>
      ) : null}

      <Col lg={6}>
        <InputWrapper>
          <Text
            type="normal"
            margin="0rem 0rem 0.25rem 0rem"
            color="black"
            fontWeight={700}
          >
            Product available from
          </Text>
          <CalenderDiv
            calendarIcon={<CalendarIconCustom />}
            clearIcon={null}
            minDate={new Date()}
            disableClock={true}
            format="MM-d-y"
            onChange={handleStartDateChange}
            value={startDate}
          />
          <Text
            fontSize={0.625}
            type="small"
            color="danger"
            textTransform="lowercase"
          >
            {startDateError}
          </Text>
        </InputWrapper>
        <Spacer height={1.5} />
      </Col>

      <Col lg={6}>
        <InputWrapper>
          <CustomDivTitle>
            <Text
              type="normal"
              margin="0rem 0rem 0.25rem 0rem"
              color="black"
              fontWeight={700}
            >
              Ends on
            </Text>
            <Checkbox
              fontSize="1rem"
              type="normal"
              margin="0rem 0rem 0.25rem 0rem"
              color="black"
              fontWeight={700}
              label="Unlimitted"
              isChecked={unLimitted}
              setIsChecked={setUnlimited}
            />
          </CustomDivTitle>

          <CalenderDiv
            disabled={unLimitted}
            calendarIcon={<CalendarIconCustom />}
            clearIcon={null}
            minDate={startDate}
            disableClock={true}
            format="MM-d-y"
            onChange={handleEndDateChange}
            value={endDate}
          />
          <Text
            fontSize={0.625}
            type="small"
            color="danger"
            textTransform="lowercase"
          >
            {endDateError}
          </Text>
        </InputWrapper>
        <Spacer height={1.5} />
      </Col>

      <Col lg={12} sm={8}>
        <StyledFlex>
          <Flexed direction="row" align="center" justify="space-between">
            <Checkbox
              fontSize="1rem"
              type="normal"
              margin="0rem 0rem 0.25rem 0rem"
              color="black"
              fontWeight={700}
              label="Pick-Up"
              isChecked={isPickUp}
              setIsChecked={setIsPickUp}
            />
            <Checkbox
              fontSize="1rem"
              type="normal"
              margin="0rem 0rem 0.25rem 0rem"
              color="black"
              fontWeight={700}
              label="Delivery"
              isChecked={isDelivery}
              setIsChecked={setIsDelivery}
            />
            <div
              style={{ display: "flex", alignItems: "center", gap: "0.4rem" }}
            >
              {/* <Flexed direction="row"> */}
              <Checkbox
                fontSize="1rem"
                type="normal"
                margin="0rem 0rem 0.25rem 0rem"
                color="black"
                fontWeight={700}
                label="Farmer Market"
                isChecked={isFarmerMarket}
                setIsChecked={setIsFarmerMarket}
                isDisabled={privateLocation}
              />
              <div
                style={{ cursor: "pointer", fontWeight: "bold" }}
                onClick={() => {
                  toast.success(
                    "If you attend different markets on different days, go to your account > Posts > Copy Post, and update the location and days as needed. Once you receive an order, prepare it for buyers to pick up. Your location will be fully disclosed on our platform",
                    {
                      autoClose: 10000,
                    }
                  );
                }}
              >
                [ ! ]
              </div>
            </div>
            <div
              style={{ display: "flex", alignItems: "center", gap: "0.4rem" }}
            >
              <Checkbox
                fontSize="1rem"
                type="normal"
                margin="0rem 0rem 0.25rem 0rem"
                color="black"
                fontWeight={700}
                label="Private Location"
                isChecked={privateLocation}
                setIsChecked={setprivateLocation}
                isDisabled={isFarmerMarket}
              />

              <div
                style={{ cursor: "pointer", fontWeight: "bold" }}
                onClick={() => {
                  toast.success(
                    "Your private location will only display the city, state, and zip code to the public. Your complete address will be included in the order confirmation sent to the buyer's email. You can connect on Gamba for further communication.",
                    {
                      autoClose: 10000,
                    }
                  );
                }}
              >
                [ ! ]
              </div>
            </div>
          </Flexed>
        </StyledFlex>
        <Spacer height={2} />
      </Col>
      {isPickUp && (
        <div style={{ width: "100%" }}>
          <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
            <Col lg={6} md={6} sm={10.3} xs={9.8}>
              <>
                <div
                  style={{
                    fontSize: "1rem",
                    fontWeight: "700",
                    color: "#3F3F41",
                  }}
                >
                  Pickup Location
                </div>
                <GooglePlacesInput
                  setSelectedPlace={setPickupLocation}
                  style={{ fontStyle: "normal", height: "42px" }}
                />

                <Spacer height={1.5} />
              </>
            </Col>
            <Col lg={6} md={6} sm={10.3} xs={9.8}>
              <>
                <Flexed direction="row" gap="1">
                  <div style={{ flex: "1 1 0%" }}>
                    <div
                      style={{
                        fontSize: "1rem",
                        fontWeight: "700",
                        color: "#3F3F41",
                      }}
                    >
                      Start time
                    </div>
                    <DropDown
                      required
                      hasChanged={(value: any) => {
                        setPickTimeErr("");
                        setPickStartTime(value);
                      }}
                      options={options}
                    />
                  </div>
                  <div style={{ flex: "1 1 0%" }}>
                    <div
                      style={{
                        fontSize: "1rem",
                        fontWeight: "700",
                        color: "#3F3F41",
                      }}
                    >
                      End time
                    </div>

                    <DropDown
                      required
                      hasChanged={(value: any) => {
                        setPickTimeErr("");
                        setPickEndTime(value);
                      }}
                      options={options}
                    />
                  </div>
                </Flexed>
                {pickTimeErr && (
                  <Text
                    fontSize={0.625}
                    type="small"
                    color="danger"
                    textTransform="lowercase"
                    style={{ position: "absolute" }}
                  >
                    {pickTimeErr}
                  </Text>
                )}

                <Spacer height={1.5} />
              </>
            </Col>
          </div>
          <Col lg={10.5} md={10.5} sm={10.3} xs={9.8}>
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <p
                  style={{
                    fontSize: "1rem",
                    fontWeight: "700",
                    color: "#3F3F41",
                  }}
                >
                  Pickup Days{" "}
                </p>
                <WeekDays className="weekdays">
                  <Checkbox
                    setIsChecked={() => handleCheck("Mon.")}
                    isChecked={selectedDays.includes("Mon.")}
                    label="Mon."
                  />
                  <Checkbox
                    setIsChecked={() => handleCheck("Tue.")}
                    isChecked={selectedDays.includes("Tue.")}
                    label="Tue."
                  />
                  <Checkbox
                    setIsChecked={() => handleCheck("Wed.")}
                    isChecked={selectedDays.includes("Wed.")}
                    label="Wed."
                  />
                  <Checkbox
                    setIsChecked={() => handleCheck("Thurs.")}
                    isChecked={selectedDays.includes("Thurs.")}
                    label="Thurs."
                  />
                  <Checkbox
                    setIsChecked={() => handleCheck("Fri.")}
                    isChecked={selectedDays.includes("Fri.")}
                    label="Fri."
                  />
                  <Checkbox
                    setIsChecked={() => handleCheck("Sat.")}
                    isChecked={selectedDays.includes("Sat.")}
                    label="Sat."
                  />
                  <Checkbox
                    setIsChecked={() => handleCheck("Sun.")}
                    isChecked={selectedDays.includes("Sun.")}
                    label="Sun."
                  />
                </WeekDays>
              </div>
              <Spacer height={1.5} />
            </>
          </Col>
        </div>
      )}
      {/* {isFarmerMarket && (
        <Col lg={10.5} md={10.5} sm={10.3} xs={9.8}>
          <p>
            If you attend different markets on different days, go to your
            account &gt; Posts &gt; Copy Post, and update the location and days
            as needed. Once you receive an order, prepare it for buyers to pick
            up. Your location will be fully disclosed on our platform
          </p>
          <Spacer height={1.5} />
        </Col>
      )} */}
      {/* {privateLocation && (
        <Col lg={10.5} md={10.5} sm={10.3} xs={9.8}>
          <>
            <p>
              Your private location will only display the city, state, and zip
              code on Gamba. Your complete address will be provided in the order
              confirmation sent to the buyer's email. You can communicate
              further on Gamba to make the transaction smoother.
            </p>
            <Spacer height={1.5} />
          </>
        </Col>
      )} */}
      {isDelivery && (
        <Col lg={10.5} md={10.5} sm={10.3} xs={9.8}>
          <>
            <CustomInputField
              type="text"
              // disabled={!privateLocation}
              bgTransparent
              label="Delivery in Mile Radius"
              placeholder="Enter the mile you deliver"
              handleChange={(e: any) => {
                setDistanceError("");
                if (e === "") {
                  setDistanceError("delivery distance is required");
                }
                setDistance(e);
              }}
              value={distance}
              error={distanceError}
              errorMsg={distanceError}
              required
              allowOnlyNumbers={true}
            />
            <Spacer height={1.5} />
          </>
        </Col>
      )}
      {/* {privateLocation && (
        <div style={{ position: "relative" }}>
          <RadiusText align="center" direction="row">
            <Text type="small" color="gray">
              Miles
            </Text>
            <Spacer height={1.5} />
          </RadiusText>
        </div>
      )} */}
      {/* {!privateLocation && <Col lg={6} />} */}

      <Col lg={6}>
        <AutoComplete
          tags={chemicalsUsed}
          setTags={(e: any) => setChemicalsUsed(e)}
          bgTransparent={true}
          disabled={toggle === true}
          label="Chemical Used"
          suggestions={chemicals}
          error={chemicalsError}
          errorMsg={chemicalsError}
          setError={setChemicalsError}
        ></AutoComplete>
        <Spacer height={1.5} />
      </Col>

      <Col
        lg={6}
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Flexed
          direction="row"
          justify="space-between"
          aling="end"
          style={{ width: "100%" }}
        >
          <div />
          {/* <Button
            ifClicked={() => setChemicalForm(!chemicalForm)}
            label="Add new"
          ></Button> */}

          <OrganicFlexed
            direction="row"
            gap={0.5}
            align="center"
            justify="flex-end"
          >
            <Flexed direction="row" gap="0.5" align={"center"}>
              <div
                style={{ cursor: "pointer", fontWeight: "bold" }}
                onClick={() => {
                  setShowModal(!showModal);
                }}
              >
                [ ! ]
              </div>
              <Text type="medium" color="black">
                Organic
              </Text>
              <Toggle toggle={toggle} setToggle={setToggle} />
            </Flexed>
          </OrganicFlexed>
        </Flexed>
      </Col>
      {/* {chemicalForm && <AddChemicalForm onSuccess={chemicalCreated} />} */}

      <Col>
        <Spacer height={1.5} />
        <CustomInputField
          // maxLength={500}
          type="textarea"
          bgTransparent
          label="Caption (Upto 150 Words)"
          placeholder="Information about your product to help people understand it better"
          handleChange={(e: any) => {
            setCaptionError("");
            if (e === "") {
              setCaptionError("Description is required");
            }
            const words = e.split(" ");
            if (words.length > 149) {
              const truncatedValue = words.slice(0, 149).join(" ");
              setCaption(truncatedValue);
            } else {
              setCaption(e);
            }
          }}
          value={caption}
          error={captionError}
          errorMsg={captionError}
          required
          textAreaRows={6}
        />
        <Spacer height={1.5} />
      </Col>

      {isChemicalsErrorModalOpen && (
        <ChemicalsErrorModal
          onClose={() => {
            setIsChemicalsErrorModalOpen(false);
          }}
        />
      )}
      {isAllowToOrderModalOpen && (
        <AllowToOrderModal
          onClose={() => {
            setIsAllowToOrderModalOpen(false);
          }}
        />
      )}
    </>
  );
};

const WeekDays = styled.div`
  display: flex;
  gap: 20px;
  & .eTfXTe {
    & .bplObn {
      padding-left: 0 !important;
    }
  }
`;

const InputWrapper = styled.div`
  position: relative;
`;

const CalendarIconCustom = styled(BiCalendarAlt)`
  color: ${palette.gray};
  font-size: 1.5rem;
`;
const StyledFlex = styled(Flexed)`
  align-self: center;
  width: 100%;
  justify-content: center;
`;
const StyledWrapper = styled.div`
  position: absolute;
  top: -0.8rem;
  left: 0.5rem;
  font-weight: 500;
  background-color: ${palette.white};
  padding: 0 0.2rem;
  z-index: 1;
`;

const CalenderDiv = styled(DateTimePicker)<any>`
  font-family: "Lato-Regular", sans-serif;
  width: 100%;
  line-height: 1.25rem;
  outline: none;
  font-weight: 400;
  text-align: left;
  font-size: 0.875rem;
  border-radius: 0.5rem;
  padding: 0.6rem 1.25rem;
  border: 1px solid
    ${({ error, disabled, isDarkTheme }) =>
      disabled
        ? `${palette.green}`
        : error
        ? `${palette.danger}`
        : isDarkTheme
        ? `${palette.stroke}`
        : `${palette.stroke}`};
  color: ${({ disabled, isDarkTheme }) =>
    disabled || isDarkTheme
      ? `${palette.text_black}`
      : `${palette.text_black}`};
  // cursor: ${({ disabled }) => (disabled ? `no-drop` : `pointer`)};
  background: ${({ disabled, bgTransparent, isDarkTheme }) =>
    bgTransparent
      ? "transparent"
      : disabled
      ? `${palette.silver}`
      : isDarkTheme
      ? `${palette.black}`
      : `${palette.white}`};

  // &:hover {
  // 	box-shadow: 0 0 0.31rem ${({ error, disabled }) =>
    disabled ? "none" : error ? `${palette.danger}` : "rgba(0, 0, 0, 0.25)"};
  // }
  &:focus {
    border: 1px solid
      ${({ error, disabled }) =>
        disabled
          ? "none"
          : error
          ? `${palette.danger}`
          : `${palette.Btn_dark_green}`};
  }
  &::placeholder {
    color: ${palette.gray_100};
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${({ disabled, isDarkTheme }) =>
      disabled || isDarkTheme ? `${palette.silver}` : `${palette.gray_100}`};
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    // color: ${palette.gray_100};
  }
`;
const TextLabel = styled(Text)`
  font-weight: 500;
  text-transform: normal;
`;

const RadiusText = styled(Flexed)`
  position: absolute;
  top: 2.5rem;
`;
const CustomSpacer = styled.div`
  ${media.lg`height: 1.688rem`};
`;
const OrganicFlexed = styled(Flexed)``;
export const CustomDivTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export default ProductStepperTwo;
