import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const withVerification = (WrappedComponent) => {
  return (props) => {
    const userDetails = useSelector((state: any) => state.auth.userDetails);

    if (Object.keys(userDetails).length > 0 && !userDetails?.is_verified) {
      return <Navigate to="/sign-in?verification=true" />;
    }

    return <WrappedComponent {...props} />;
  };
};

export default withVerification;
