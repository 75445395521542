import { FC } from "react";

interface IInfo {
  title: string;
  handleClick?: () => void;
}

const Info: FC<IInfo> = ({ title, handleClick }) => {
  return (
    <div
      onClick={() => {
        if (handleClick) {
          handleClick();
        }
      }}
    >
      <img
        src="/images/icons/info.svg"
        alt="info"
        style={{ height: "24px", width: "24px" }}
        title={title}
      />
    </div>
  );
};

export default Info;
