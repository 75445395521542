import React, { FC, useEffect, useState } from "react";
import { Flexed, Text } from "../styled/shared";
import styled from "styled-components";
import { palette } from "../styled/colors";
import { useSelector } from "react-redux";
import { calculateDistance } from "./utils";

type IProductDistance = {
  lat: number;
  lng: number;
};

const ProductDistance: FC<IProductDistance> = ({ lat, lng }) => {
  const { user_lat, user_lng } = useSelector((state: any) => state.global);
  const global = useSelector((state: any) => state.global);
  const [distance, setDistance] = useState<any>("");

  useEffect(() => {
    getDistance();
  }, []);

  const getDistance = async () => {
    if (!user_lat || !user_lng || !lat || !lng) {
      return;
    }
    const pointA = { lat: user_lat, lng: user_lng };
    const pointB = { lat, lng };
    const res: any = await calculateDistance(pointA, pointB);
    if (res?.text) {
      setDistance(res?.text);
    }
  };

  if (!distance) {
    return null;
  }

  return (
    <Flexed margin="0.8rem 0rem 0rem 0rem" direction="row" align="center">
      <LocationIcons src="/images/icons/location.svg" />
      <Distance>{distance}</Distance>
    </Flexed>
  );
};

export default ProductDistance;

const LocationIcons = styled.img`
  width: 0.875rem;
`;

const Distance = styled(Text)`
  color: ${palette.gray};
  font-size: 12px;
  font-weight: 400;
  overflow: hidden;
  white-space: nowrap;
  line-height: normal;
`;
