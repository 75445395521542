import { useEffect, useState } from "react";

const useGetWindowSize = () => {
  const [size, setSize] = useState([window.innerHeight, window.innerWidth]);
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    const _position = window.pageYOffset;
    setScrollPosition(_position);
  };

  useEffect(() => {
    const handleResize = () => {
      setSize([window.innerHeight, window.innerWidth]);
    };
    window.addEventListener("resize", handleResize);
    window.addEventListener("scroll", handleScroll, { passive: true });
  }, []);
  return { size, scrollPosition };
};

export default useGetWindowSize;
