import React, { FC, useState } from "react";
import PlacesAutocomplete from "react-places-autocomplete";
import styled from "styled-components";
import { palette } from "../styled/colors";

interface IGooglePlacesInput {
  setSelectedPlace: React.Dispatch<React.SetStateAction<string>>;
  style?: any;
}

const GooglePlacesInput: FC<IGooglePlacesInput> = ({
  setSelectedPlace,
  style,
}) => {
  const [address, setAddress] = useState("");

  const handleSelect = (address: string) => {
    if (address !== "") {
      const addressWithoutCountry = address.split(",").slice(0, -1).join(",");

      setAddress(addressWithoutCountry.trim());
      setSelectedPlace(addressWithoutCountry.trim());
      // _dispatch(saveSearchAddress(addressWithoutCountry.trim()));
    }
  };

  return (
    <PlacesAutocomplete
      value={address}
      onChange={setAddress}
      onSelect={handleSelect}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps }: any) => (
        <div style={{ flex: "1 1 0%" }}>
          <>
            <LocationInputWrapper>
              <Location src="/images/icons/location_icon.svg" />

              <GoogleAutoLocation
                borderRadius={"0.2rem"}
                margin={"0"}
                padding={"0.8rem 0rem 0.8rem 3rem"}
                value={address}
                {...getInputProps()}
                placeholder="Enter Zip Code, City, Neighborhood"
                style={style}
              />
            </LocationInputWrapper>
            <ListItems open={suggestions?.length > 0} style={{ zIndex: "100" }}>
              {suggestions?.map((suggestion: any, i: any) => {
                const style = {
                  backgroundColor: suggestion.active ? "#32CD32" : "white",
                };
                return (
                  <LocationList
                    key={i + "mapkey"}
                    {...getSuggestionItemProps(suggestion, {
                      style,
                    })}
                  >
                    {suggestion?.description}
                  </LocationList>
                );
              })}
            </ListItems>
          </>
        </div>
      )}
    </PlacesAutocomplete>
  );
};

const LocationInputWrapper = styled.div`
  position: relative;
`;

const Location = styled.img`
  position: absolute;
  top: 25%;
  left: 5px;
  margin: auto;
  width: 20px;
  height: 18px;
`;

const GoogleAutoLocation = styled.input<any>`
  font-family: "Lato-Regular", sans-serif;
  font-style: italic;
  line-height: normal;
  outline: none;
  font-weight: 400;
  text-align: left;
  font-size: 12px;
  border-radius: 0.5rem;
  padding: 0.45rem 0.5rem 0.45rem 1.5rem;
  border: 1px solid ${palette.stroke};
  background: #f0f2f5;
  width: 100%;
  color: #4b4b4b;
  &:focus {
    border: 1px solid
      ${({ error, disabled }) =>
        disabled ? "none" : error ? palette.danger : palette.Btn_dark_green};
  }
  &::placeholder {
    color: ${palette.gray_100};
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${({ disabled, isDarkTheme }) =>
      disabled || isDarkTheme ? `${palette.silver}` : `${palette.gray_100}`};
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    // color: ${palette.gray_100};
  }
`;

const ListItems = styled.div<any>`
	position: absolute;
	background: ${palette.white};
	z - index: 1;
	width: calc(100 % - 1.875rem);
	border: ${({ open }) => (open ? `1px solid ${palette.stroke}` : null)};

	border - radius: 1rem;

	max - height: 15rem;
	overflow - y: auto;
`;

const LocationList = styled.div`
	background - color: ${palette.white};
	cursor: pointer;
	line - height: 2rem;
	padding: 0.5rem 0.8rem;
	border - bottom: 1px solid ${palette.stroke};
	font - family: 'Lato-Regular', sans - serif;
	text - transform: normal;
	color: ${palette.heading_color};
		&:hover {
		background: ${palette.Btn_dark_green};
		color: ${palette.white};
	}

		&: first - child {
		border - top - left - radius: 0.375rem;
		border - top - right - radius: 0.375rem;
	}
		&: last - child {
		border - bottom - left - radius: 0.375rem;
		border - bottom - right - radius: 0.375rem;
	}
		&: last - child {
		border - bottom: 0;
	}
`;

export default GooglePlacesInput;
