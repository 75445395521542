import React, { useEffect, useState } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import styled from "styled-components";
import { Col, media } from "styled-bootstrap-grid";
import { Text, Arrow, Flexed, getCurrentAddress } from "../styled/shared";
import { palette } from "../styled/colors";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import AddPostModal from "./modals/AddPostModal";
import MobViewAddPost from "./MobViewAddPost";
import Loader from "./common/Loader";
import { useDispatch } from "react-redux";
import {
  saveSearchAddress,
  saveSearchLat,
  saveSearchLog,
  saveSearchText,
  setIsLoading,
} from "../actions/authActions";
import LoginPopupModel from "./modals/LoginPopupModel";
import { isMobile } from "./utils";
import { setUserLatLngs } from "../actions/globalActions";
import GooglePlacesInput from "./GooglePlacesInput";

const SubNavBar = () => {
  const _navigate = useNavigate();
  const { pathname } = useLocation();
  const authToken =
    localStorage.getItem("authorization") ||
    sessionStorage.getItem("authorization");
  const isLoading: any = useSelector<any>((state: any) => state.auth.isLoading);
  const [isAddPostModalOpen, setIsAddPostModalOpen] = useState(false);
  const [address, setAddress]: any = useState("");
  const [select, setSelect] = useState("Photo");
  const [isModalFooterOpen, setIsModalFooterOpen] = useState(true);
  const [serachText, setSearchText] = useState("");
  const _dispatch = useDispatch();
  const searchQuery: any = useSelector<any>(
    (state: any) => state.auth.topSearch
  );
  // const addressQuery: any = useSelector<any>(
  //   (state: any) => state.auth.searchAddress
  // );

  const [loginPopup, setLoginPopup] = useState(false);

  const handleSelect = (address: string) => {
    if (address !== "") {
      const addressWithoutCountry = address.split(",").slice(0, -1).join(",");

      setAddress(addressWithoutCountry.trim());
      _dispatch(saveSearchAddress(addressWithoutCountry.trim()));

      geocodeByAddress(address)
        .then((results) => getLatLng(results[0]))
        .then((latLng) => {
          const { lat, lng } = latLng;
          _dispatch(setUserLatLngs({ lat, lng }));
        })
        .catch((error) => console.error("Error", error));

      // geocodeByAddress(addressWithoutCountry.trim())
      //   .then((results: any[]) => {
      //     if (results.length > 0) {
      //       const location = results[0].geometry.location;
      //       const placeId = results[0].place_id;

      //       fetchPlaceDetails(placeId);

      //       return {
      //         lat: location.lat(),
      //         lng: location.lng(),
      //       };
      //     } else {
      //       throw new Error("No results found for the specified address.");
      //     }
      //   })
      //   .then((latLng: any) => {
      //     setSearchText("");
      //     _dispatch(saveSearchText(""));
      //     _dispatch(saveSearchLat(latLng?.lat));
      //     _dispatch(saveSearchLog(latLng?.lng));
      //     _dispatch(saveSearchAddress(addressWithoutCountry.trim()));
      //   })
      //   .catch((err: any) => console.log("Error: ", err));
    }
  };

  const fetchPlaceDetails = (placeId) => {
    const service = new window.google.maps.places.PlacesService(
      document.createElement("div")
    );
    const request = { placeId };

    service.getDetails(request, (result, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        const processed = processAddressComponents(result?.address_components);

        setAddress(processed);
      }
    });
  };

  const processAddressComponents = (components) => {
    const abbreviationMap = {
      Street: "St.",
      Avenue: "Ave.",
      Boulevard: "Blvd.",
      Drive: "Dr.",
      Road: "Rd.",
      Lane: "Ln.",
      Terrace: "Ter.",
      Place: "Pl.",
      Court: "Ct.",
    };

    const filteredComponents = components.filter(
      (component) =>
        component.types.includes("locality") ||
        component.types.includes("administrative_area_level_1") ||
        component.types.includes("route")
    );

    const address = filteredComponents
      .map((component) => {
        const type = component.types[0];
        let shortName = component.short_name;
        if (type === "route" && abbreviationMap[component.long_name]) {
          shortName = abbreviationMap[component.long_name];
        }
        return shortName;
      })
      .join(", ");

    return address;
  };

  useEffect(() => {
    setSearchText(searchQuery);
  }, [searchQuery]);

  // useEffect(() => {
  //   setAddress(addressQuery);
  // }, [addressQuery]);

  useEffect(() => {
    if (address === "") {
      _dispatch(saveSearchLat(null));
      _dispatch(saveSearchLog(null));
      _dispatch(saveSearchAddress(""));
    }
  }, [address]);

  useEffect(() => {
    getLatLong();
  }, []);

  const getLatLong = async () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const address = await getCurrentAddress(
            position.coords.latitude,
            position.coords.longitude
          );

          setAddress(address.split(",").slice(0, -1).join(","));
        },
        (error) => {
          console.error("Error getting geolocation:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  return (
    <>
      <Grid
        style={{
          display: "flex",
          flexDirection: isMobile() ? "column" : "row",
        }}
      >
        <GooglePlacesInput
          key={address}
          defaultValue={address}
          onSelect={handleSelect}
          style={{
            flex: "1 1 0%",
            maxWidth: isMobile() ? "100%" : "200px",
            width: "100%",
          }}
        />

        <div
          style={{
            display: "flex",
            alignItems: "center",
            flex: "1 1 0%",
            width: "100%",
            marginTop: isMobile() ? "10px" : "0px",
          }}
        >
          <InputWrapper
            style={{
              marginRight: "10px",
              marginLeft: isMobile() ? "0" : "10px",
              flex: "1 1 0%",
            }}
          >
            {!pathname.includes("/cart") && !pathname.includes("/settings") && (
              <>
                <Input
                  className=""
                  placeholder="Search"
                  value={serachText}
                  style={{ height: "30px" }}
                  onChange={(e: any) => {
                    setSearchText(e.target.value);
                    // setAddress("");
                    _dispatch(saveSearchLat(null));
                    _dispatch(saveSearchLog(null));
                    _dispatch(saveSearchAddress(""));
                    _dispatch(saveSearchText(e.target.value));
                  }}
                />
                <Search src="/images/icons/search.svg" alt="search" />
              </>
            )}
          </InputWrapper>
          <Flexed direction="row" width="auto">
            <div>
              <PostWebView>
                <PostButton
                  onClick={() => {
                    if (authToken !== null) {
                      setIsAddPostModalOpen(true);
                    } else {
                      setLoginPopup(true);
                      // _navigate('/sign-in')
                    }
                  }}
                >
                  <img src="/images/icons/add_btn.svg" alt="add" />
                  Post
                </PostButton>
              </PostWebView>
            </div>
          </Flexed>
        </div>
      </Grid>

      {isAddPostModalOpen && (
        <AddPostModal
          setSelect={setSelect}
          setIsModalFooterOpen={setIsModalFooterOpen}
          isModalFooterOpen={isModalFooterOpen}
          select={select}
          onClose={() => {
            setIsAddPostModalOpen(false);
          }}
        />
      )}

      {isLoading && <Loader visible={isLoading} />}
      {loginPopup && <LoginPopupModel onClose={() => setLoginPopup(false)} />}
    </>
  );
};

const Grid = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SocialIcon = styled.img<any>`
  width: 1.5rem;
  color: ${({ pathname }) =>
    pathname === "/products" ? palette.Btn_dark_green : palette.text};
`;
const ProductsIcon = styled.img<any>`
  width: 1.3rem;
  color: ${({ pathname }) =>
    pathname === "/products" ? palette.Btn_dark_green : palette.text};
`;
const CalenderIcon = styled.img<any>`
  width: 1.4rem;
  color: ${({ pathname }) =>
    pathname === "/calendar" ? palette.Btn_dark_green : palette.text};
`;
// const Input = styled.input`
//   font-family: "Lato-Regular", sans-serif;
//   line-height: normal;
//   outline: none;
//   font-weight: 400;
//   text-align: left;
//   font-size: 1.25rem;
//   border-radius: 0.5rem;
//   border-top-left-radius: 0;
//   border-bottom-left-radius: 0;
//   padding: 0.3rem 0.5rem 0.3rem 1.5rem;
//   border: 1px solid rgb(248, 249, 250);
//   color: ${palette.black};
//   background: #f0f2f5;
//   width: 100%;
//   &::placeholder {
//     color: ${palette.gray_100};
//   }
// `;

const Input = styled.input<any>`
  font-family: "Lato-Regular", sans-serif;
  font-style: italic;
  line-height: normal;
  outline: none;
  font-weight: 400;
  text-align: left;
  font-size: 14px;
  border-radius: 0.5rem;
  padding: 0.45rem 0.5rem 0.45rem 1.5rem;
  border: 1px solid ${palette.stroke};
  background: #f0f2f5;
  width: 100%;
  color: #4b4b4b;
  &:focus {
    border: 1px solid
      ${({ error, disabled }) =>
        disabled ? "none" : error ? palette.danger : palette.Btn_dark_green};
  }
  &::placeholder {
    color: ${palette.gray_100};
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${({ disabled, isDarkTheme }) =>
      disabled || isDarkTheme ? `${palette.silver}` : `${palette.gray_100}`};
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    // color: ${palette.gray_100};
  }
`;

const GoogleAutoLocation = styled.input<any>`
  font-family: "Lato-Regular", sans-serif;
  font-style: italic;
  line-height: normal;
  outline: none;
  font-weight: 400;
  text-align: left;
  font-size: 12px;
  border-radius: 0.5rem;
  padding: 0.45rem 0.5rem 0.45rem 1.5rem;
  border: 1px solid ${palette.stroke};
  background: #f0f2f5;
  width: 100%;
  color: #4b4b4b;
  &:focus {
    border: 1px solid
      ${({ error, disabled }) =>
        disabled ? "none" : error ? palette.danger : palette.Btn_dark_green};
  }
  &::placeholder {
    color: ${palette.gray_100};
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${({ disabled, isDarkTheme }) =>
      disabled || isDarkTheme ? `${palette.silver}` : `${palette.gray_100}`};
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    // color: ${palette.gray_100};
  }
`;

const LocationList = styled.div`
	background - color: ${palette.white};
	cursor: pointer;
	line - height: 2rem;
	padding: 0.5rem 0.8rem;
	border - bottom: 1px solid ${palette.stroke};
	font - family: 'Lato-Regular', sans - serif;
	text - transform: normal;
	color: ${palette.heading_color};
		&:hover {
		background: ${palette.Btn_dark_green};
		color: ${palette.white};
	}

		&: first - child {
		border - top - left - radius: 0.375rem;
		border - top - right - radius: 0.375rem;
	}
		&: last - child {
		border - bottom - left - radius: 0.375rem;
		border - bottom - right - radius: 0.375rem;
	}
		&: last - child {
		border - bottom: 0;
	}
`;

const CustomCol = styled(Col)`
  display: flex;
  min-height: 4.375rem;
  align-items: center;
`;

const MobMenuIcons = styled.div`
  display: flex;
  cursor: pointer;
`;

const PostWebView = styled.div`
  display: block;
`;
const LocationInputWrapper = styled.div`
  position: relative;
`;

const InputWrapper = styled.div`
  position: relative;
  // max-width: 15rem;
`;

const LabelWrapper = styled.div``;

const Search = styled.img`
  position: absolute;
  top: 22%;
  right: 0.5rem;
  margin: auto;
  width: 20px;
  height: 18px;
`;
const Location = styled.img`
  position: absolute;
  top: 25%;
  left: 5px;
  margin: auto;
  width: 20px;
  height: 18px;
`;

const ListItems = styled.div<any>`
	position: absolute;
	background: ${palette.white};
	z - index: 1;
	width: calc(100 % - 1.875rem);
	border: ${({ open }) => (open ? `1px solid ${palette.stroke}` : null)};

	border - radius: 1rem;

	max - height: 15rem;
	overflow - y: auto;
`;

const PostButton = styled.div`
  background-color: ${palette.green_200};
  color: ${palette.white};
  padding: 0.625rem 1.2rem;
  font-size: 1rem;
  border-radius: 2rem;
  font-family: "Lato-Bold", sans-serif;
  height: 40px;
  display: flex;
  font-weight: 700;
  gap: 0.5rem;
  align-items: center;
  cursor: pointer;
  &:hover {
    /* opacity: 0.7; */
    transition: background-color 0.3s ease 0.2s;
    background-color: rgb(64, 115, 10);
  }
  ${media.xs`
	padding: 0.6rem 1rem;
	 `};
  ${media.sm`
	padding: 0.6rem 1rem;
	 `};
  ${media.md`
	padding: 0.625rem 1.2rem;
	 `};
  ${media.lg`
	padding: 0.625rem 1.2rem;
	 `}
`;

const CartIcon = styled.span`
  font-size: 1.5rem;
`;

export const MenuText = styled(Text)<any>`
  position: relative;
  color: ${palette.text};
  letter-spacing: 0.05em;
  /* font-weight: 600; */
  font-size: ${({ fontSize, active }) =>
    fontSize ? fontSize : active ? "1rem" : "1rem"};

  cursor: pointer;
  &:not(:last-child) {
    /* padding-right: 2.5rem; */
  }

  & ${Arrow} {
    color: ${({ scroll, path, isDarkTheme }) =>
      isDarkTheme
        ? `${palette.silver}`
        : scroll < 0 && path
        ? `${palette.text_black}`
        : `${palette.gray}`};
  }

  &:hover ${Arrow} {
    transform: rotate(-180deg);
    color: ${palette.orange};
  }

  &:hover {
    color: ${({ active }) =>
      active ? palette.Btn_dark_green : palette.Btn_dark_green};
    transition: color 0.1s ease 0.1s;
  }
`;

const StyledText = styled(MenuText)`
  display: block;
  font-weight: ${({ active }) => (active ? 600 : 400)};
  ${media.xs`
	display:none
	`}
`;

const Tab = styled(Flexed)<any>`
  display: flex;
  /* justify-content: center; */
  padding: 0.5rem 0rem;
  /* min-width: 33%; old property */
  /* min-width: 20%; */
  /* margin-bottom: 0.125rem; */
  border-bottom: ${({ active }) =>
    active ? `0.188rem solid ${palette.orange}` : `0.188rem solid transparent`};
  /* border-right: ${({ border }) =>
    border ? ` 0.063rem solid ${palette.gray}` : ""}; */
  cursor: pointer;

  /* background-color: ${({ active }) => (active ? "#e5e6e9" : "")}; */
  /* opacity: 0.1; */
  &:hover {
    border-bottom: 0.188rem solid ${palette.orange};
  }
  &:hover ${SocialIcon} {
    color: ${palette.orange};
  }
  &:hover ${CalenderIcon} {
    color: ${palette.orange};
  }
  &:hover ${ProductsIcon} {
    color: ${palette.orange};
  }
  &:hover ${StyledText} {
    color: ${palette.orange};
  }

  /* ${media.md`
    min-width: 11rem;
  `}
	${media.lg`
    min-width: 12.8rem;
  `}
	${media.xl`
    min-width: 16rem;
  `} */
`;
export default SubNavBar;
