import * as types from "../actions/types";

const initialState: any = {
  productsActiveTab: "products",
  selectedProductCategory: null,
  user_lat: null,
  user_lng: null,
};

export default function globalReducer(state = initialState, action: any) {
  switch (action.type) {
    case types.PRODUCTS_ACTIVE_TAB:
      return {
        ...state,
        productsActiveTab: action.value,
      };
    case types.SELECT_PRODUCT_CATEGORY:
      return {
        ...state,
        selectedProductCategory: action.value,
      };
    case types.SET_USER_LAT_LNG:
      return {
        ...state,
        user_lat: action.value.lat,
        user_lng: action.value.lng,
      };

    default:
      return state;
  }
}
