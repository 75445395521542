import React, { useEffect, useState } from "react";
import { media } from "styled-bootstrap-grid";
import styled from "styled-components";
import { Flexed, Text } from "../styled/shared";
import { palette } from "../styled/colors";
import { getCategory } from "../apis/apis";
import Button from "./common/Button";
import { useDispatch } from "react-redux";
import { selectProductCategory } from "../actions/globalActions";
import { useSelector } from "react-redux";

const ProductCategories = () => {
  const dispatch = useDispatch();
  const { selectedProductCategory } = useSelector((state: any) => state.global);
  const [categories, setCategories] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);

  const reducedCategories = isExpanded
    ? categories
    : categories.filter((_, i) => i < 7);

  useEffect(() => {
    getAllCategories();
  }, []);
  const getAllCategories = async () => {
    try {
      const response = await getCategory();
      const data = response.data.category.map((d: any) => ({
        id: d.id,
        value: d.title,
        label: d.title,
      }));
      setCategories(data);
    } catch (error) {}
  };

  const handleClick = (cat: string) => {
    dispatch(selectProductCategory(cat));
  };

  return (
    <OnlyForWeb className="most-viewed p-2 mt-1">
      {categories?.length > 0 && (
        <Text
          type="normal"
          className="mb-3"
          fontWeight={700}
          style={{ color: "#868686" }}
        >
          Categories
        </Text>
      )}
      {reducedCategories?.map((item: any, index: any) => (
        <List key={index}>
          <li>
            <Flexed direction="row" align="center" gap={0.5} cursor="pointer">
              <Name
                color="black_100"
                type="normal"
                pointer
                lineHeight={1.5}
                fontWeight={
                  selectedProductCategory === item.label.toLowerCase()
                    ? "bold"
                    : 500
                }
                textTransform="capitalize"
                onClick={() => handleClick(item.label.toLowerCase())}
              >
                <span>{item?.label}</span>
              </Name>
            </Flexed>
          </li>
        </List>
      ))}
      {categories.length > 7 && (
        <Text
          type="normal"
          className="mb-3"
          fontWeight={700}
          style={{ color: "#868686", cursor: "pointer" }}
          onClick={() => setIsExpanded(!isExpanded)}
        >
          {isExpanded ? "Show Less" : "Show More"}
        </Text>

        // <Button
        //   ifClicked={() => setIsExpanded(!isExpanded)}
        //   label={isExpanded ? "Show Less" : "Show More"}
        //   margin="10px 0 0 0"
        // ></Button>
      )}
    </OnlyForWeb>
  );
};

const OnlyForWeb = styled.div<any>`
  display: none;
  ${media.xl`display: block`};
`;

const List = styled.ul`
  list-style: none;
  padding: 0px;
  margin-bottom: 0;

  & > li {
    cursor: pointer;
    color: ${palette.text_description};
    &:hover {
      color: ${palette.orange};
      transition: color 0.1s ease-in-out;
    }
  }
`;

const Name = styled(Text)<any>`
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  //   height: 1.3rem;
  white-space: nowrap;
  padding: 4px;
  padding-left: 10px;
  border-radius: 6px;
  &:hover {
    background: ${palette.x_side};
  }
`;

export default ProductCategories;
