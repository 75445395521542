import * as types from "../actions/types";

const initialState: any = {
  selectedProductCategory: "all",
};

export default function globalReducer(state = initialState, action: any) {
  switch (action.type) {
    case types.SELECT_PRODUCT_CATEGORY:
      return {
        ...state,
        selectedProductCategory: action.value,
      };

    default:
      return state;
  }
}
