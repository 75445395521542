import {
  useStripe,
  useElements,
  PaymentElement,
  CardElement,
} from "@stripe/react-stripe-js";
import { useEffect, useState } from "react";
import { addCard, getCards, processPayment } from "../../apis/apis";
import Button from "../common/Button";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { clearCart } from "../../actions/cartActions";
import { useNavigate } from "react-router-dom";
import { palette } from "../../styled/colors";
import { media } from "styled-bootstrap-grid";
import { Flexed, Spacer } from "../../styled/shared";

const StripeCardLayout = (data) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isProcessing, setIsProcessing] = useState(false);
  const [message, setMessage] = useState("");
  const cart: any = useSelector<any>((state: any) => state.cart);
  const [cards, setSavedCards] = useState([] as string[]);
  const [loading, setLoading] = useState(false);
  const [cartTotal, setTotal] = useState(0);
  const dispatch = useDispatch();
  const MySwal = withReactContent(Swal);
  let _navigate = useNavigate();

  useEffect(() => {
    if (data.sellersAndProducts.length == 0) {
      // _navigate("/products");
      return;
    }

    setTotal(
      cart?.products?.reduce((result, item) => {
        return result + item.quantity * item.discountPrice;
      }, 0)
    );

    getSavedCards();
  }, []);

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    setIsProcessing(true);

    elements.submit();
    const result = await stripe.createPaymentMethod({
      elements,
    });

    if (result.error) {
      setMessage(result.error.message as string);
    } else {
      console.log(result?.paymentMethod?.id);

      const res: any = await addCard(result?.paymentMethod?.id);

      if (res.success) {
        setMessage(res.message);
      } else {
        setMessage(res?.message);
        // setMessage(res?.message.raw.message);
      }

      if (
        Array.isArray(data.sellersAndProducts) &&
        data.sellersAndProducts.length > 0
      ) {
        const total: number = cart?.products?.reduce((result, item) => {
          return result + item.quantity * item.discountPrice;
        }, 0);
        console.log(total);
        const paymentResponse: any = await processPayment(
          data.sellersAndProducts,
          "debitOrCreditCard",
          total,
          result?.paymentMethod?.id
        );
        console.log(paymentResponse);
        if (paymentResponse.success) {
          MySwal.fire({
            customClass: {
              container: "my-custom-swal",
            },
            showCloseButton: true,
            denyButtonText: paymentResponse.message,
            imageUrl: "/images/ThankYouGreen.png",
          }).then(() => {
            dispatch(clearCart("0"));
            _navigate("/products");
          });
        } else {
          setMessage(paymentResponse.message);
        }
      }
    }
    setIsProcessing(false);
  };

  const getSavedCards = async () => {
    setLoading(true);
    const cards: any = await getCards();
    if (cards.success) {
      setSavedCards(cards.data as string[]);
    }
    setLoading(false);
  };

  const payNow = async (paymentMethodId) => {
    setLoading(true);
    if (
      Array.isArray(data.sellersAndProducts) &&
      data.sellersAndProducts.length > 0
    ) {
      const total: number = cart?.products?.reduce((result, item) => {
        return result + item.quantity * item.discountPrice;
      }, 0);
      console.log(total);
      const paymentResponse: any = await processPayment(
        data.sellersAndProducts,
        "debitOrCreditCard",
        total,
        paymentMethodId
      );
      console.log(paymentResponse);
      if (paymentResponse.success) {
        MySwal.fire({
          customClass: {
            container: "my-custom-swal",
          },
          showCloseButton: true,
          denyButtonText: paymentResponse.message,
          imageUrl: "/images/ThankYouGreen.png",
        }).then(() => {
          dispatch(clearCart("0"));
          _navigate("/products");
        });
      } else {
        setMessage(paymentResponse.message);
      }
    }
    setLoading(false);
  };

  return (
    <>
      <Wrapper>
        <Flexed direction="column" gap="0">
          <Spacer height={0.325} />
          <form onSubmit={handleSubmit}>
            <PaymentElement />
            <div className={`d-flex gap-2`}>
              <div>I agree to the total charge of ${cartTotal}</div>
              <div>
                <Button
                  disabled={isProcessing || !stripe || !elements}
                  id="submit"
                  label={
                    isProcessing
                      ? "Processing ... "
                      : Array.isArray(data.sellersAndProducts) &&
                        data.sellersAndProducts.length > 0
                      ? "Pay Now"
                      : "Save Card"
                  }
                  type="primary"
                ></Button>
                {message && <div id="payment-message">{message}</div>}
              </div>
            </div>
          </form>
        </Flexed>
        <Spacer height={2} />
        <Flexed direction="column" gap="0">
          <h2 className="fs-4 fw-semibold">Pay using saved cards</h2>
          <CardDetailsTable cardDetails={cards} payNow={payNow} />
          <a href="/settings">Manage your credit cards</a>
        </Flexed>
      </Wrapper>
    </>
  );
};

const CardDetailsTable = ({ cardDetails, payNow }) => {
  return (
    <table className="w-100" cellPadding={5} cellSpacing={5}>
      <thead>
        <tr>
          <th style={{ width: "250px" }}>Default</th>
          <th style={{ width: "100px" }}>Brand</th>
          <th style={{ width: "400px" }}>Last 4 Digits</th>
          <th style={{ width: "250px" }}>Action</th>
        </tr>
      </thead>
      <tbody>
        {cardDetails.map((card) => (
          <tr
            className={
              card.isDefault ? "payment-cards default-card" : "payment-cards"
            }
            key={card.id}
          >
            <td
              style={{ padding: "20px" }}
              className="d-flex justify-content-start align-items-center gap-2"
            >
              <input
                className="form-check"
                type="checkbox"
                checked={card.isDefault}
                id="cb-checkout"
                disabled
              />
            </td>
            <td style={{ padding: "20px" }}>{card.brand}</td>
            <td style={{ padding: "20px" }}>xxxx-xxxx-xxxx-{card.last4}</td>
            <td style={{ padding: "20px" }} className="justify-content-end">
              {card.isDefault && (
                <Button
                  type="primary"
                  label="Pay Now"
                  ifClicked={() => {
                    payNow(card.id);
                  }}
                ></Button>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const Wrapper = styled.div`
  background-color: ${palette.white};
  width: 100%;

  flex-direction: column;
  padding: 24px !important;
  align-items: start;
  border-radius: 1rem;
  ${media.lg`align-items: center; padding: 0rem 0.6rem;`}
  ${media.xl`align-items: start;`}
	margin-bottom : 20px !important;
`;
export default StripeCardLayout;
