import * as types from "./types";

export const selectProductTab = (payload: any) => {
  return {
    type: types.PRODUCTS_ACTIVE_TAB,
    value: payload,
  };
};
export const selectProductCategory = (payload: any) => {
  return {
    type: types.SELECT_PRODUCT_CATEGORY,
    value: payload,
  };
};
export const setUserLatLngs = (payload: any) => {
  return {
    type: types.SET_USER_LAT_LNG,
    value: payload,
  };
};
