import React, { FC, useState } from "react";
import LoginPopupModel from "./modals/LoginPopupModel";
import { duplicateProduct } from "../apis/apis";
import styled from "styled-components";
import { palette } from "../styled/colors";

interface IDuplicatePost {
  cardIndex: number;
  content: any;
  onSuccess: (id: any, payload: any) => void;
}

const DuplicatePost: FC<IDuplicatePost> = ({
  cardIndex,
  content,
  onSuccess,
}) => {
  const isUserLogIn =
    localStorage.getItem("authorization") ||
    sessionStorage.getItem("authorization");
  const [loginPopup, setLoginPopup] = useState(false);
  const [isDuplicating, setIsDuplicating] = useState(false);

  const duplicatePost = async () => {
    setIsDuplicating(true);
    try {
      const res = await duplicateProduct(content.id);
      onSuccess(cardIndex, res);
    } catch (error) {
      console.log(error, "see error.");
    } finally {
      setIsDuplicating(false);
    }
  };

  return (
    <>
      <DropMenu
        className="d-flex align-items-center gap-2"
        onClick={() => {
          isUserLogIn !== null ? duplicatePost() : setLoginPopup(true);
        }}
        style={{ cusror: "pointer" }}
      >
        <svg width="14" height="14" viewBox="0 0 512 512">
          <path
            d="M441 58.9L453.1 71c9.4 9.4 9.4 24.6 0 33.9L424 134.1 377.9 88 407 58.9c9.4-9.4 24.6-9.4 33.9 0zM209.8 256.2L344 121.9 390.1 168 255.8 302.2c-2.9 2.9-6.5 5-10.4 6.1l-58.5 16.7 16.7-58.5c1.1-3.9 3.2-7.5 6.1-10.4zM373.1 25L175.8 222.2c-8.7 8.7-15 19.4-18.3 31.1l-28.6 100c-2.4 8.4-.1 17.4 6.1 23.6s15.2 8.5 23.6 6.1l100-28.6c11.8-3.4 22.5-9.7 31.1-18.3L487 138.9c28.1-28.1 28.1-73.7 0-101.8L474.9 25C446.8-3.1 401.2-3.1 373.1 25zM88 64C39.4 64 0 103.4 0 152V424c0 48.6 39.4 88 88 88H360c48.6 0 88-39.4 88-88V312c0-13.3-10.7-24-24-24s-24 10.7-24 24V424c0 22.1-17.9 40-40 40H88c-22.1 0-40-17.9-40-40V152c0-22.1 17.9-40 40-40H200c13.3 0 24-10.7 24-24s-10.7-24-24-24H88z"
            fill="#5B626A"
          />
        </svg>
        {isDuplicating ? "Duplicating" : "Duplicate"}
      </DropMenu>
      {loginPopup && <LoginPopupModel onClose={() => setLoginPopup(false)} />}
    </>
  );
};

const DropMenu = styled.span<any>`
  color: ${palette.text};
  font-size: 0.875rem;
  padding: 0.425rem 1rem 0.425rem 0.8rem;
  text-decoration: none;
  display: block;
  background-color: ${palette.white};
  text-align: left;
  cursor: pointer;
  &:hover {
    color: ${palette.orange};
    background: ${palette.fbBg};
    transition: color 0.1s ease 0.1s;
  }
`;

export default DuplicatePost;
