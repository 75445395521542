// weavy-component.jsx
"use client";
import { WyContext, WyMessenger } from "@weavy/uikit-react";
import axios from "axios";
import { doGetUserProfile } from "../apis/apis";

export default function WeavyComponent() {
  async function tokenFactory() {
    try {
      const userProfile = await doGetUserProfile();

      const res = await axios.post(
        `${
          process.env.REACT_APP_PUBLIC_WEAVY_URL
        }/api/users/${encodeURIComponent(
          userProfile.data.first_name.split(" ")[0]
        )}/tokens`,
        {},
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_PUBLIC_WEAVY_APIKEY}`,
          },
        }
      );

      return res.data.access_token;
    } catch (error) {}
  }

  return (
    <>
      <WyContext
        url={process.env.REACT_APP_PUBLIC_WEAVY_URL}
        tokenFactory={tokenFactory}
      >
        <WyMessenger
          style={{
            minHeight: "calc(100vh - 100px)",
          }}
        ></WyMessenger>
      </WyContext>
    </>
  );
}
