import React, { useEffect, useState } from "react";
import { Flexed, Spacer, Text } from "../styled/shared";
import { media } from "styled-bootstrap-grid";
import styled from "styled-components";
import { getPostById } from "../apis/apis";
import { useDispatch, useSelector } from "react-redux";
import { setIsLoading } from "../actions/authActions";
import { palette } from "../styled/colors";
import { AiFillYoutube } from "react-icons/ai";
import { FaFacebookF } from "react-icons/fa";
import { ImTwitter } from "react-icons/im";
import { AiFillLinkedin } from "react-icons/ai";
import { getPopularPost } from "../apis/apis";
import moment from "moment-timezone";
import { colorPicker } from "../components/utils";
import { useLocation } from "react-router-dom";
import MainTabs from "../components/MainTabs";
import { useNavigate } from "react-router-dom";
import SidebarFooter from "./SidebarFooter";
import ProductCategories from "./ProductCategories";

const MainCategorySideBar = ({
  sellerId,
  setSinglePost,
  isContactUsOpen,
  setIsContactUsOpen,
  setIsAboutOpen,
  isAboutOpen,
  setSelectedBtn,
  setSellerId,
  setSingleEvent,
  setSelectCategory,
}: any) => {
  let _navigate = useNavigate();
  const { pathname }: any = useLocation();

  const [topPost, setTopPost]: any = useState();
  const [color, setColor] = useState<any>("");
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isDataProgress, setIsDataProgress]: any = useState(true);
  const currentRoute = useSelector<any>(
    (state: any) => state.auth.currentRoute
  );
  const _dispatch = useDispatch();
  const useWindowSize = () => {
    const [size, setSize] = useState([window.innerHeight, window.innerWidth]);
    useEffect(() => {
      const handleResize = () => {
        setSize([window.innerHeight, window.innerWidth]);
      };
      window.addEventListener("resize", handleResize);
      window.addEventListener("scroll", handleScroll, { passive: true });
    }, []);
    return size;
  };

  const [screenHeight, screenWidth] = useWindowSize();

  const handleScroll = () => {
    const _position = window.pageYOffset;
    setScrollPosition(_position);
  };

  const getTopPost = async () => {
    let response = await getPopularPost();
    setTopPost(response);
  };

  const getColor = async () => {
    setColor(color);
    return color;
  };

  useEffect(() => {
    getTopPost();
  }, [currentRoute]);

  const handleItemClick = async (id: any) => {
    _dispatch(setIsLoading(true));
    setIsDataProgress(true);

    let response = await getPostById(id);
    setSinglePost(response?.viewAllPosts);
    _navigate("/community");
    setIsContactUsOpen(false);
    setIsAboutOpen(false);
    _dispatch(setIsLoading(false));
    setIsDataProgress(false);
  };

  return (
    <StyledWrapper scroll={scrollPosition} screenHeight={screenHeight}>
      <MdHide
        scroll={scrollPosition}
        isHome={pathname === "/community" || pathname === "/products"}
        screenHeight={screenHeight}
      >
        <MainTabs
          setSelectedBtn={setSelectedBtn}
          setSinglePost={setSinglePost}
          setSellerId={setSellerId}
          setSingleEvent={setSingleEvent}
        />
        {pathname === "/products" && <ProductCategories />}
        <OnlyForWeb className="most-viewed mt-md-5  p-2">
          {topPost?.data?.length > 0 && (
            <Text
              type="normal"
              className="mb-3"
              // color="black_100"
              // fontWeight={700}
              style={{ color: "#868686", fontWeight: "700" }}
            >
              Most Viewed Posts
            </Text>
          )}
          {topPost?.data?.map((item: any, index: any) => (
            <List key={index} onClick={() => handleItemClick(item?.id)}>
              <li>
                <Flexed
                  direction="row"
                  align="center"
                  gap={0.5}
                  className="most-viewed-posts"
                >
                  <div>
                    <Profile>
                      {item?.user?.image ? (
                        <Img
                          src={`https://imagescontent.s3.us-east-1.amazonaws.com/${item?.user?.image}`}
                        />
                      ) : (
                        <Img
                          src={`https://imagescontent.s3.us-east-1.amazonaws.com/${
                            item?.user?.first_name &&
                            item?.user?.first_name[0].toLowerCase() + ".png"
                          }`}
                        />
                      )}
                    </Profile>
                  </div>
                  <div>
                    <Name
                      color="black_100"
                      type="normal"
                      pointer
                      lineHeight={1.5}
                      fontWeight={500}
                      textTransform="capitalize"
                    >
                      <span>{item?.title}</span>
                    </Name>

                    <Name
                      color="gray"
                      type="normal"
                      fontSize={0.75}
                      pointer
                      lineHeight={1.2}
                      fontWeight={500}
                    >
                      <em>
                        <span className="me-1 block">by</span>
                        <span>
                          {item?.user.first_name} {item?.user.last_name}
                        </span>
                      </em>
                    </Name>
                  </div>
                </Flexed>
              </li>
            </List>
          ))}
        </OnlyForWeb>
      </MdHide>

      <SidebarFooter
        scrollPosition={scrollPosition}
        screenHeight={screenHeight}
        setSelectCategory={setSelectCategory}
      />
    </StyledWrapper>
  );
};
const MdHide = styled.div<any>`
  display: ${({ scroll, isHome, screenHeight }) =>
    scroll > screenHeight / 2 - 100 && isHome ? "none" : "block"};
`;

export const StyledWrapper = styled.div<any>`
  position: sticky;
  top: 168.03px;
  overflow-y: auto;
  display: flex;

  flex-direction: column;

  margin-bottom: 1rem;

  ${media.xl`
		top: 100px;
		height: calc(100vh - 132.03px);
		border-bottom:none;
		margin-bottom: 0rem;
	`}
  justify-content: ${({ scroll, screenHeight }) =>
    scroll > screenHeight / 2 - 100 ? "flex-end" : "flex-start"};
  ::-webkit-scrollbar {
    display: none;
  }
`;

const OnlyForWeb = styled.div<any>`
  display: none;
  ${media.xl`display: block`};
`;

const Name = styled(Text)<any>`
  text-overflow: ellipsis;
  overflow: hidden;
  width: 10rem;
  height: 1.3rem;
  white-space: nowrap;
`;

const List = styled.ul`
  list-style: none;
  padding: 0px;
  margin-bottom: 0;

  & > li {
    cursor: pointer;
    color: ${palette.text_description};
    &:hover {
      color: ${palette.orange};
      transition: color 0.1s ease-in-out;
    }
  }
`;

const Divider = styled.div`
  height: 1px;
  background: ${palette.stroke};
  margin: 1.875rem 0rem;
`;

const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 100%;
`;

const Content = styled.div`
  width: 6rem;
  /* height: 7rem; */
`;

const Image = styled.img`
  width: 100%;
  cursor: pointer;
  /* height: 3.809rem; */
`;

const Profile = styled.div<any>`
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 100%;
  background: ${({ styledColor }) =>
    styledColor ? `${styledColor} !important` : ""};
  color: ${palette.black};
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  position: relative;
`;

export default MainCategorySideBar;
