import React from "react";

const UpcomingActiviites = () => {
  return (
    <div>
      <p style={{ color: "#868686", fontWeight: "700" }}>Upcoming Activities</p>
    </div>
  );
};

export default UpcomingActiviites;
